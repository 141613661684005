import { json } from '@remix-run/node';

export const loader = async () => {
    return json(null, { status: 404 });
}

import { Link } from '@remix-run/react'

import {
    Flex,
    Stack,
    Heading,
    Button
} from '@chakra-ui/react'

import theme from '../Config/theme'

const CatchBoundary = () => {
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            px={10}
        >
            <Stack textAlign={'center'} spacing={5}>
                <Heading>Couldn't find what you were looking for!</Heading>
                <Link to='/'>
                    <Button
                        _active={{}}
                        _focus={{}}
                        _hover={{}}
                        bgColor={theme.default.defaultColor}
                        borderRadius={'full'}
                        w={'full'}
                    >
                        Go to homepage!
                    </Button>
                </Link>
            </Stack>

        </Flex>
    )
}

export default CatchBoundary